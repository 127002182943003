import React, { useState, useEffect, useContext } from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Grid, ListItemText, MenuItem, Select } from '@material-ui/core';

import { i18n } from '../../translate/i18n';
import api from '../../services/api';
import ButtonWithSpinner from '../ButtonWithSpinner';
import toastError from '../../errors/toastError';
import { AuthContext } from '../../context/Auth/AuthContext';
import { toast } from 'react-toastify';
import { Field, Formik, Form } from 'formik';

const filter = createFilterOptions({
    trim: true,
});

const NewTicketGroupModal = ({ modalOpen, onClose, initialContact }) => {
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchParam, setSearchParam] = useState('');
    const [selectedContacts, setSelectedContacts] = useState([]);
    const [selectedQueue, setSelectedQueue] = useState('');
    const [connections, setConnections] = useState([]);
    const [selectedConnection, setSelectedConnection] = useState('');
    const [titleGroup, setTitleGroup] = useState('');
    const { user } = useContext(AuthContext);

    // Carrega contatos iniciais (se houver)
    useEffect(() => {
        if (initialContact?.id !== undefined) {
            setOptions([initialContact]);
            setSelectedContacts([initialContact]);
        }
    }, [initialContact]);

    // Carrega conexões de WhatsApp disponíveis
    useEffect(() => {
        const fetchWhatsapps = async () => {
            try {
                const { data } = await api.get('whatsapp');
                setConnections(data);
                setLoading(false);
            } catch (err) {
                setLoading(false);
                toastError(err);
            }
        };

        fetchWhatsapps();
    }, []);

    // Busca contatos conforme o termo de pesquisa
    useEffect(() => {
        if (!modalOpen || searchParam.length < 3) {
            setLoading(false);
            return;
        }

        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {
                try {
                    const { data } = await api.get('contacts', {
                        params: { searchParam },
                    });
                    setOptions(data.contacts);
                    setLoading(false);
                } catch (err) {
                    setLoading(false);
                    toastError(err);
                }
            };

            fetchContacts();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, modalOpen]);

    // Função para fechar o modal
    const handleClose = () => {
        onClose();
        setSearchParam('');
        setSelectedContacts([]);
    };

    // Função para criar o grupo e o ticket
    const handleCreateGroupAndTicket = async (contactsAddGroup) => {
        if (!contactsAddGroup || !titleGroup || selectedQueue === '' || selectedConnection === '') {
            toast.error('Todos os campos são obrigatórios');
            return;
        }

        setLoading(true);
        try {
            const { data: ticket } = await api.post('/creatGroupAndTicket', {
                contactsAddGroup,
                titleGroup,
                queueId: selectedQueue,
                whatsappId: selectedConnection,
                userId: user.id,
                status: 'open',
            });
            setSelectedContacts([]);
            onClose(ticket);
        } catch (err) {
            toastError(err);
        }
        setLoading(false);
    };

    // Função para selecionar múltiplos contatos
    const handleSelectOption = (e, newValue) => {
        if (Array.isArray(newValue) && newValue.length > 0) {
            setSelectedContacts(newValue.map((contact) => `${contact.number}@s.whatsapp.net`));
        }
    };

    // Cria opções para adicionar contato manualmente
    const createAddContactOption = (filterOptions, params) => {
        const filtered = filter(filterOptions, params);

        if (params.inputValue !== '' && searchParam.length >= 3) {
            filtered.push({
                name: `${params.inputValue}`,
            });
        }

        return filtered;
    };

    // Renderiza a opção para o Autocomplete
    const renderOption = (option) => {
        if (option.number) {
            return `${option.name} - ${option.number}`;
        } else {
            return `${i18n.t('newTicketModal.add')} ${option.name}`;
        }
    };

    // Renderiza o label da opção no Autocomplete
    const renderOptionLabel = (option) => {
        if (option.number) {
            return `${option.name} - ${option.number}`;
        } else {
            return `${option.name}`;
        }
    };

    // Autocomplete para selecionar contatos
    const renderContactAutocomplete = () => {
        return (
            <Grid xs={12} item>
                <Autocomplete
                    fullWidth
                    multiple
                    options={options}
                    loading={loading}
                    clearOnBlur
                    autoHighlight
                    freeSolo
                    clearOnEscape
                    getOptionLabel={renderOptionLabel}
                    renderOption={renderOption}
                    filterOptions={createAddContactOption}
                    onChange={(e, newValue) => handleSelectOption(e, newValue)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={i18n.t('newTicketModal.fieldLabel')}
                            variant="outlined"
                            autoFocus
                            onChange={(e) => setSearchParam(e.target.value)}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                />
            </Grid>
        );
    };

    return (
        <>
            <Dialog open={modalOpen} onClose={handleClose}>
                <DialogTitle>{i18n.t('Novo Grupo')}</DialogTitle>
                <DialogContent dividers>
                    <Formik
                        initialValues={{ title: '' }}
                        onSubmit={(values) => {
                            // Lógica de envio
                        }}
                    >
                        {({ values, handleChange }) => (
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid xs={12} item>
                                        <Field
                                            as={TextField}
                                            label={i18n.t('Nome Do Grupo')}
                                            name="title"
                                            onChange={(e) => setTitleGroup(e.target.value)}
                                            value={titleGroup}
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                        />
                                    </Grid>
                                    {renderContactAutocomplete()}
                                    <Grid xs={12} item>
                                        <Select
                                            fullWidth
                                            displayEmpty
                                            variant="outlined"
                                            value={selectedQueue}
                                            onChange={(e) => setSelectedQueue(e.target.value)}
                                            renderValue={() =>
                                                selectedQueue === '' ? 'Selecione uma fila' : user.queues.find((q) => q.id === selectedQueue)?.name
                                            }
                                        >
                                            {user.queues.map((queue, key) => (
                                                <MenuItem dense key={key} value={queue.id}>
                                                    <ListItemText primary={queue.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                    <Grid xs={12} item>
                                        <Select
                                            fullWidth
                                            displayEmpty
                                            variant="outlined"
                                            value={selectedConnection}
                                            onChange={(e) => setSelectedConnection(e.target.value)}
                                            renderValue={() =>
                                                selectedConnection === '' ? 'Selecione uma conexão' : connections.find((conn) => conn.id === selectedConnection)?.name
                                            }
                                        >
                                            {connections.map((connection) => (
                                                <MenuItem key={connection.id} value={connection.id}>
                                                    <ListItemText primary={connection.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary" disabled={loading} variant="outlined">
                        {i18n.t('newTicketModal.buttons.cancel')}
                    </Button>
                    <ButtonWithSpinner
                        variant="contained"
                        type="button"
                        disabled={!selectedContacts.length}
                        onClick={() => handleCreateGroupAndTicket(selectedContacts)}
                        color="primary"
                        loading={loading}
                    >
                        {i18n.t('newTicketModal.buttons.ok')}
                    </ButtonWithSpinner>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default NewTicketGroupModal;
